<template>
<router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  created() {
    if (this._isMobile()) {
      console.log("手机端");
      this.$router.replace('/m_index');
    } else {
      console.log("pc端");
      this.$router.replace('/pc');
    }
  },
  methods: {
    // 判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
