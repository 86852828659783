<template>
  <div>
    <h1>Login</h1>
    <form>
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username">
            <div></div>
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password">
            <div></div>
      <button type="submit" @click.prevent="login">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login() {
      // perform login logic here
    }
  }
}
</script>
