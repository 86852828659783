<template>
    <div id="homo_back" class="hello">
      <h1>首页</h1>
      <h2>闫家岳</h2>
      <router-link to="/lo">17班</router-link>
      <h5></h5>
      <router-link to="/pc">平板切换PC界面</router-link>  

          <!-- 
      <h3>DebugInfo:</h3>
      <h4>Mobile</h4>
 -->
    </div>
  </template>
  <script>
  var ym = window.location.origin;
  export default {
    setup() {},
    methods: {
      //事件修饰符：stop，prevent，capture，self,once,passive
      //按键修饰符：enter，tab,delete,esc,up,down,left,right
      //鼠标事件修饰符：left,right,middle
      run() {
        var xyx = ym + "/webg/";
        console.log(xyx);
        window.location.href = xyx;
      },
    },
  };
  </script>
  <style>
  #homo_back{
  background:url("../assets/backg/x3.jpg");
  width:100%;		
  height:100%;		
  position:fixed;
  background-size:100% 100%;}
  </style>
  
  