<template>
  <div class="container">
    <header>
      <h1>回忆录</h1>
    </header>
    <footer>
      <p>&copy; 闫家岳</p>
    </footer>
  </div>
  <div>
    <img src="..\assets\c17\a1701.jpg" alt="01">
    <img src="..\assets\c17\a1702.jpg" alt="01">
    <img src="..\assets\c17\a1703.jpg" alt="01">
    <img src="..\assets\c17\a1704.jpg" alt="01">
    <img src="..\assets\c17\a1705.jpg" alt="01">
    <img src="..\assets\c17\a1706.jpg" alt="01">
    <img src="..\assets\c17\a1707.jpg" alt="01">
    <img src="..\assets\c17\a1708.jpg" alt="01">
  </div>
  <!--
      <div>
    <img @click="togame" src="../assets/acar.png" alt="ACAR">
  </div>
  -->

</template>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}
</style>

<script>
var ym = window.location.origin;
export default {
  setup() {},
  methods: {
    //事件修饰符：stop，prevent，capture，self,once,passive
    //按键修饰符：enter，tab,delete,esc,up,down,left,right
    //鼠标事件修饰符：left,right,middle
    togame() {
      var xyx = ym + "/webg/";
      console.log(xyx);
      window.location.href = xyx;
    },
  },
};

</script>
