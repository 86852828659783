import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";

import router from './router';
import App from './App.vue'

const app = createApp(App)
//require('./mock/LoginService.js')
app.use(router)
app.use(NutUI)
app.use(ElementPlus)
app.mount('#app')