import { createRouter, createWebHashHistory} from 'vue-router'
//import HomeView from "/src/Views/homeViews.vue"
import MYhome from "/src/Views/myHome.vue"
import layOut from "/src/Views/lay_out.vue"
import xhz from "/src/Views/game_s.vue"
import login from "/src/Views/log_in.vue"
import m_index from "/src/Views/m_index.vue"


const routes = [
    {
        path: '/pc',
        component: MYhome
    },
    {
        path: '/lo',
        component: layOut
    },
    {
        path: '/u3d',
        component: xhz
    },
    {
        path: '/tst',
        component: login
    },
    {
        path: '/m_index',
        component: m_index
    },


]
const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router;